import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogPost.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`👉 Retrouvez-nous les 27, 28, 29 mai 2021 en ligne !`}</p>
    <p>{`\\
Nous sommes ravis d’avoir été sélectionnés pour exposer La Consigne GreenGo à la World Expo de `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/feed/hashtag/?keywords=changenow2021&highlightedUpdateUrns=urn%3Ali%3Aactivity%3A6800019462771752960"
      }}>{`#`}{`ChangeNOW2021`}</a>{`, l’événement mondial des solutions pour la planète ♻️\\
En plus, nous avons la chance de pouvoir réaliser un Pitch pour expliquer notre solution, le samedi 29 Mai à 16h. Venez nombreux ! \\
\\
Cet événement rassemble de nombreux acteurs du changement et nous serons disponible de vous présenter notre service digital et clé en main d’emballages réutilisables et consignés !\\
\\
Acteurs du changement, rejoignez-nous et rencontrez des investisseurs, médias, grands groupes, institutionnels ou encore des talents qui vous aideront à multiplier votre impact !\\
\\
Plus d’informations sur `}<a parentName="p" {...{
        "href": "https://changenow.world/"
      }}>{`https://changenow.world/`}</a>{`  pour prendre vos tickets`}</p>
    <p>{`Si vous souhaitez nous rencontrer, n'hésitez pas à contacter Charlène, `}<a parentName="p" {...{
        "href": "mailto:charlene@laconsignegreengo.com"
      }}>{`charlene@laconsignegreengo.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      